import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { LoggingService } from './log.service';
import { SettingsService } from '../settings/settings.service';
import { RestfulService } from './restful.service';
import * as ENDPOINT from '../../shared/constant/endPoint.js';
import { RequestBody } from '../interfaces/admin.interface';
import { CompanyAction, Company } from '../interfaces/company.interface';
import { getEndPointWithParams } from '../../../assets/js/util.js';
import { Observable } from 'rxjs';


@Injectable()
export class CompanyService {

  typeAction: CompanyAction;

  constructor(private logger: LoggingService, private settings: SettingsService, private restful: RestfulService) { }


  companyList(reqBody: RequestBody): Observable<any> {
    const endPoint = ENDPOINT.company_all;
    return this.post(endPoint, reqBody);
  }

  getCompanyInfo(idCompany): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.company_info, idCompany);
    return this.get(endPoint);
  }

  createCompany(companyInfo: Company): Observable<any> {
    const endPoint = ENDPOINT.company_create;
    return this.post(endPoint, companyInfo);
  }
  updateCompany(companyInfo: Company): Observable<any> {
    const endPoint = ENDPOINT.company_update;
    return this.put(endPoint, companyInfo);
  }



  setTypeAction(typeAction: CompanyAction): void {
    this.typeAction = typeAction;
  }

  getTypeAction(): CompanyAction {
    return this.typeAction;
  }


  post(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }


  put(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(response);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }
}
