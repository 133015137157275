import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TranslatorService {

  private defaultLanguage: string = 'it';

  private availablelangs = [
    { code: 'en', text: 'English' },
    { code: 'it', text: 'Italian' }
  ];

  constructor(public translate: TranslateService, private http: HttpClient) {

    if (!translate.getDefaultLang()) {
      translate.setDefaultLang(this.defaultLanguage);
    }
    this.useLanguage();

  }

  getDefaultLanguage() {
    return this.translate.getDefaultLang();
  }

  useLanguage(lang: string = null) {
    this.translate.use(lang || this.translate.getDefaultLang());
  }

  getAvailableLanguages() {
    return this.availablelangs;
  }

  getTranslationByKey(key: string): Observable<any> {
    return this.translate.get(key)
  }

  getTranslationByKeyAndLanguage(key: string, lang: string): Observable<any> {
    const translateIn = this.availablelangs.find(availablelang => availablelang.code == lang) ? lang : this.translate.currentLang;
    return this.http.get(`/assets/i18n/${translateIn}.json`).pipe(
      map((res) => {
        let translation = res;
        key.split('.').forEach((k) => {
          translation = translation[k];
        });
        return translation as string;
      })
    );
  }
}
