import { PortalService } from './../services/portal.service';
import { RestfulService } from './../services/restful.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { getApplicationVersion } from '../../../assets/js/util.js';
import * as ENDPOINT from '../../../app/shared/constant/endPoint.js';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
declare var $: any;

@Injectable()
export class SettingsService {

    private user: any;
    private app: any;
    private layout: any;
    private errorTranslation: any;
    public baseurl = environment.restBaseUrl;

    constructor(private translate: TranslateService, private router: Router,
      private http: HttpClient) {
        this.errorTranslation = this.translate.get('error');
        // User Settings
        // -----------------------------------
        this.user = {
            name: '',
            job: '',
            picture: 'assets/img/user/02.jpg'
        };

        // App Settings - footer
        // -----------------------------------
        this.app = {
            name: 'Soluzione 1',
            description: 'Spesotto di Soluzione1',
            year: ((new Date()).getFullYear()),
            versionFe: "V. " + environment.version  // versionFe: "v " + getApplicationVersion()
        };

        // Layout Settings - basic settings for layout & Co.
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };

    }

    getAppSetting(name) {
      return name ? this.app[name] : this.app;
    }
    getUserSetting(name) {
        return name ? this.user[name] : this.user;
    }
    getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }

    setAppSetting(name, value) {
        if (typeof this.app[name] !== 'undefined') {
            this.app[name] = value;
        }
    }
    setUserSetting(name, value) {
        if (typeof this.user[name] !== 'undefined') {
            this.user[name] = value;
        }
    }
    setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

    sessionExpired() {
      localStorage.clear();
    }

    manageErrorMsg(outcome) {
      this.errorTranslation = this.translate.get('error');
      let message = "";
      switch (outcome.code) {
        case '0000':
          message = this.errorTranslation.value.e_0000;
        break;
        case '0001':
          message = this.errorTranslation.value.e_0001;
        break;
        case '0002':
          message = this.errorTranslation.value.e_0002;
        break;
        case '0003':
          message = this.errorTranslation.value.e_0003;
        break;
        case '0004':
          message = this.errorTranslation.value.e_0004;
        break;
        case '0005':
          this.sessionExpired();
          this.router.navigate(["/login/0"]);
          message = this.errorTranslation.value.e_0005;
        break;
        case '0006':
          message = this.errorTranslation.value.e_0006;
        break;
        case '0007':
          this.sessionExpired();
          this.router.navigate(["/login/0"]);
          message = this.errorTranslation.value.e_0007;
        break;
        case '0008':
          message = this.errorTranslation.value.e_0008;
        break;
        case '0009':
          message = this.errorTranslation.value.e_0009;
        break;
        case '0010':
          message = this.errorTranslation.value.e_0010;
        break;
        case '0011':
          message = this.errorTranslation.value.e_0011;
        break;
        case '0012':
          message = this.errorTranslation.value.e_0012;
        break;
        case '0013':
          message = this.errorTranslation.value.e_0013;
        break;
        case '0014':
          message = this.errorTranslation.value.e_0014;
        break;
        case '0016':
          message = this.errorTranslation.value.e_0016;
        break;
        case '0017':
          message = this.errorTranslation.value.e_0017;
        break;
        case '0020':
          message = this.errorTranslation.value.e_0020;
          break;
        case '0022':
          message = this.errorTranslation.value.e_0022;
        break;
        case '0023':
          message = this.errorTranslation.value.e_0023;
        break;
        case '0024':
          message = this.errorTranslation.value.e_0024;
        break;
        case '0025':
          message = this.errorTranslation.value.e_0025;
        break;
        case '0027':
          message = this.errorTranslation.value.e_0027;
        break;
        case '0028':
          message = this.errorTranslation.value.e_0028;
        break;
        case '0029':
          message = this.errorTranslation.value.e_0029;
        break;
        case '0030':
          message = this.errorTranslation.value.e_0030;
        break;
        case '0031':
          message = this.errorTranslation.value.e_0031;
        break;
        case '0032':
          message = this.errorTranslation.value.e_0032;
        break;
        case '0033':
          message = this.errorTranslation.value.e_0033;
        break;
        case '0034':
          message = this.errorTranslation.value.e_0034;
        break;
        case '0035':
          message = this.errorTranslation.value.e_0035;
        break;
        case '0036':
          message = this.errorTranslation.value.e_0036;
        break;
        case '0037':
          message = this.errorTranslation.value.e_0037;
        break;
        case '0038':
          message = this.errorTranslation.value.e_0038;
        break;
        case '0039':
          message = this.errorTranslation.value.e_0039;
        break;
        case '0053':
          message = this.errorTranslation.value.e_0053;
        break;
        default:
          message = this.errorTranslation.value.generic_error;
        break;
      }
      return message;
    }

}
