export enum State {
  ALL = null,
  EXPIRED = 3,
  ACTIVE = 1,
  INACTIVE = 0
}

export enum Multiselect {
  EMAIL = "email",
  ROLE = "role",
  ROLE_LIST = "roleList",
  COMPANY = "company",
  USER = "user",
  RESELLER = "reseller",
  RESELLER_OWN = "resellerOwn",
  PRODUCTS = "products",
  PRODUCT_FAMILY = "productFamily",
  PRODUCT_TYPE = "productType",
  PRODUCT_CODE = "productCode",
  DELIVERY_AREA = "deliveryArea",
  AVAILABILITY_PERIOD = "availabilityPeriod",
  STATE = "state",
  UDM = "udm",
  WEEK_DAY = "weekDay",
  RESELLER_PRICE_LIST = "resellerPriceList",
  CURRENCY = "currency",
  PRICE = "price",
  ORDER_STATE = "orderState",
  PAYMENT_STATE = "paymentState",
  VAT = "vat"
}


export interface MultiselectSettings {
  singleSelection: boolean;
  text: string;
  searchPlaceholderText: string;
  selectAllText?: string;
  unSelectAllText?: string;
  enableSearchFilter: boolean;
  classes: string;
  badgeShowLimit?: number;
  disabled?: boolean;
}

// Value key MultiselectItems like enum
export interface MultiselectItems {
  currency?: MultiselectItem[];
  email?: MultiselectItem[];
  role?: MultiselectItem[];
  roleList?: MultiselectItem[];
  company?: MultiselectItem[];
  user?: MultiselectItem[];
  reseller?: MultiselectItem[];
  resellerOwn?: MultiselectItem[];
  products?: MultiselectItem[];
  productFamily?: MultiselectItem[];
  productType?: MultiselectItem[];
  productCode?: MultiselectItem[];
  deliveryArea?: MultiselectItem[];
  availabilityPeriod?: MultiselectItem[];
  state?: MultiselectItem[];
  udm?: MultiselectItem[];
  weekDay?: MultiselectItem[];
  price?: MultiselectItem[];
  orderState?: MultiselectItem[];
  paymentState?: MultiselectItem[];
  vat?: MultiselectItem[];
  // metadata
  delivery?: MultiselectItem[];
  theme?: MultiselectItem[];
  resellerPriceList?: MultiselectItem[];
}

export interface MultiselectItem {
  id: any;
  itemName: string;
}

export interface LoggedUserInfo {
  token: string;
  name: string;
  surname: string;
  enabled: boolean;
  role: string;
}

export interface RequestBody {
  paging: boolean;
  page: number;
  rows: number;
  summary?: boolean;
  orderBy?: string;
  direction?: string;
  filters: EntityEnum;
}

export interface EntityEnum {
  ORDER_VIEW?: any;
  PRICE_LIST_VIEW?: any;
  COMPANY?: any;
  RESELLER?: any;
  RESELLER_DELIVERY_AREA?: any;
  RESELLER_AVAILABILITY?: any;
  RESELLER_AVAILABILITY_DAY?: any;
  RESELLER_AVAILABILITY_DAY_TIME?: any;
  CUSTOMER?: any;
  PRODUCT?: any;
  LICENSE?: any;
  PRICE_LIST?: any;
  PRODUCT_TYPE?: any;
  PRODUCT_FAMILY?: any;
  USER?: any;
  ROLE?: any;
}

export interface SweetAlertMsg {
  loadingText?: string;
  creationText?: string;
  updatingText?: string;
  errorText?: string;
  firstError?: string;
  secondError?: string;
  warning?: string;
}
