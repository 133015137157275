import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByKey'
})
export class SortByKeyPipe implements PipeTransform {

  transform(value: any,key:any): any {
    if(value){
      if(key == null) return value;
        return value.sort((x,y) => {
          if(x[key] && y[key]) return String(x[key])>String(y[key]);
          else 0
        })
    }
    else return value;
    }
  

}
