import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '../../core/settings/settings.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(private settings: SettingsService, private router: Router) {

    // Check token data
    if (localStorage.getItem("token") === null) {
      this.settings.sessionExpired();
      this.router.navigate(["/login"]);
    }

  }

  ngOnInit() {
  }

}
