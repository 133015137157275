const packageJson = require('../../../package.json')

export function getApplicationVersion(){
    return packageJson.version;
}


export function getEndPointWithParams(endPoint) {
    var args = Array.prototype.slice.call(arguments, 1)
    var count = -1;
    return endPoint.replace(/:[a-zA-Z?]+/g, (match) => {
        count += 1;
        return args[count] !== undefined ? args[count] : match;
    });
}

export function numberWithCommas(n) {
    return (
        n
          .toFixed(2)
          .replace('.', ',')
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      )
}

export function dateFormatting(date) {
    let month = date.getMonth() + 1;
    month = month < 10 ? '0' + month : month;
    let endDate = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return date.getFullYear() + "-" + month + "-" + endDate
}


export function timezoneAdjustment(viewValue) {
    // undo the timezone adjustment we did during the formatting
    viewValue.setMinutes(viewValue.getMinutes() - viewValue.getTimezoneOffset());
    // we just want a local date in ISO format
    return viewValue.toISOString();
}

// convert 2020-5-12 - 2020-6-13 in 12/5/2020 - 13/6/2020
export function convertPeriodFormat(period) {
    var splicer = " - ";
    var res = period.split(splicer);
    var startDate = fromISOtoUkFormat(res[0]);
    var endDate = fromISOtoUkFormat(res[1]);
    period = (startDate ? startDate : "inf") + splicer + (endDate ? endDate : "inf")
    return period;
}

export function fromISOtoUkFormat(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
        return (date.getDate() < 10 ? "0" + date.getDate() : date.getDate())  + '/'
            + ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + '/'
            + date.getFullYear();
    }
}

export function convertTimeInDate(timeString) {
    if (timeString === null) return null;
    var splicer = ":";
    var d = new Date();
    var res = timeString.split(splicer);
    var hh = res[0];
    var mm = res[1];
    d.setHours(hh);
    d.setMinutes(mm);
    d.setSeconds(0);
    return d;
}

export function replaceUncorrectSymbolUrl(url) {
    return url.replace('%3D', '=');
}

export function openWindow(url) {
    var left = (screen.width / 2) - (800 / 2);
    var top = (screen.height / 2) - (600 / 2);
    window.open(url,
        "_blank"/* ,
        "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=800, height=600, left=" + left + " top=" + top */);
}