import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { LoggingService } from './log.service';
import { Observable } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { RestfulService } from './restful.service';
import { APPCONFIG } from '../../app.config';
import { getEndPointWithParams } from '../../../assets/js/util.js';
import * as ENDPOINT from '../../shared/constant/endPoint.js';
import { RequestBody } from '../interfaces/admin.interface';
import { UserAction, UserInfo } from '../interfaces/user.interface';


@Injectable()
export class UserService {

  typeActionUser: UserAction;

  constructor(private logger: LoggingService, private settings: SettingsService, private restful: RestfulService) { }

  setTypeActionUser(typeActionUser: UserAction): void {
    this.typeActionUser = typeActionUser;
  }

  getTypeActionUser(): UserAction {
    return this.typeActionUser;
  }


  usersList(reqBody: RequestBody): Observable<any> {
    const endPoint = ENDPOINT.admin_userList;
    return this.post(endPoint, reqBody);
  }

  getUserInfo(idUser: string): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.admin_userId, idUser);
    return this.get(endPoint);
  }

  deleteUser(idUser: string): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.admin_userId, idUser);
    return this.delete(endPoint);
  }

  createUser(reqBody: UserInfo): Observable<any> {
    return this.post(ENDPOINT.admin_user, reqBody);
  }

  editUser(idUser: string, reqBody: UserInfo): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.admin_userId, idUser);
    return this.put(endPoint, reqBody);
  }

  invite(idUser: number): Observable<any> {
    const reqBody: any = {};
    const endPoint = getEndPointWithParams(ENDPOINT.admin_userInvite, idUser);
    return this.put(endPoint, reqBody);
  }


  post(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }


  put(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(response);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }
}



