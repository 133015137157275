import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { LoggingService } from './log.service';
import { Observable } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { RestfulService } from './restful.service';
import { getEndPointWithParams } from '../../../assets/js/util.js';
import * as ENDPOINT from '../../shared/constant/endPoint.js';
import SimulteAPI from '../../../assets/server/simulate_api.json';
import { ProductAction, FamilyAction, ProductInfo, ProductFamily } from '../interfaces/product.interface';
import { Multiselect } from '../interfaces/admin.interface';
import { saveAs } from "file-saver/src/FileSaver.js";


@Injectable()
export class ProductService {

  typeActionProduct: ProductAction;
  typeActionFamily: FamilyAction;

  constructor(private logger: LoggingService, private settings: SettingsService, private restful: RestfulService) { }



  setTypeActionFamily(typeActionFamily: FamilyAction): void {
    this.typeActionFamily = typeActionFamily;
  }

  getTypeActionFamily(): FamilyAction {
    return this.typeActionFamily;
  }

  setTypeActionProduct(typeActionProduct: ProductAction): void {
    this.typeActionProduct = typeActionProduct;
  }

  getTypeActionProduct(): ProductAction {
    return this.typeActionProduct;
  }


  temp_getProductFamily(idReseller: string) {
    return SimulteAPI.resellers[idReseller].productFamily;
  }


  getProductById(idProduct: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.product_idProduct, idProduct);
    return this.get(endPoint);
  }

  getProductsInfo(idProducts: number[]): Observable<any> {
    const endPoint = ENDPOINT.product_productsInfo;
    return this.post(endPoint, idProducts);
  }

  getProductList(reqBody: any): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.product_listView);
    return this.post(endPoint, reqBody);
  }

  createRegistryProduct(reqBody: ProductInfo): Observable<any> {
    const endPoint = ENDPOINT.product_createProduct;
    return this.post(endPoint, reqBody);
  }

  updateRegistryProduct(reqBody: ProductInfo): Observable<any> {
    const endPoint = ENDPOINT.product_updateRegistry;
    return this.put(endPoint, reqBody);
  }

  createProductThresholds(reqBody: ProductInfo): Observable<any> {
    const endPoint = ENDPOINT.product_createProductThresholds;
    return this.post(endPoint, reqBody);
  }

  updateThresholdsProduct(reqBody: ProductInfo): Observable<any> {
    const endPoint = ENDPOINT.product_updateThreshold;
    return this.put(endPoint, reqBody);
  }

  getFamilyList(reqBody: any): Observable<any> {
    const endPoint = ENDPOINT.product_productFamily;
    return this.post(endPoint, reqBody);
  }

  createProductFamily(reqBody: ProductFamily): Observable<any> {
    const endPoint = ENDPOINT.product_createProductFamily;
    return this.post(endPoint, reqBody);
  }

  getProductFamilyById(idProductFamily: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.product_idProductFamily, idProductFamily);
    return this.get(endPoint);
  }

  updateProductFamily(reqBody: ProductFamily): Observable<any> {
    const endPoint = ENDPOINT.product_updateProductFamily;
    return this.put(endPoint, reqBody);
  }

  deleteProductFamily(idProductFamily: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.product_deleteProductFamily, idProductFamily);
    return this.delete(endPoint);
  }

  getMultiselectItems(multiselectType: Multiselect, id?: number): Observable<any> {
    let endPoint;
    switch (multiselectType) {
      case Multiselect.PRODUCT_FAMILY:
        endPoint = getEndPointWithParams(ENDPOINT.multiselect_familyList_idCompany, id);
        break;
    }
    return this.get(endPoint);
  }

  downloadFamilyTermsAndConditionsFile(idProductFamily: number, fileName: string): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.productFamily_termsAndCondition, idProductFamily);
    return this.get_downloadFile(endPoint, fileName); 
  }

  downloadProductTermsAndConditionsFile(idProduct: number, fileName: string): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.product_termsAndCondition, idProduct);
    return this.get_downloadFile(endPoint, fileName); 
  }



  post(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }


  put(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get_downloadFile(endPoint: string, fileName: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.get_downloadFile(endPoint).subscribe(
        (response: HttpResponse<any>) => {
          this.responseHandler_downloadFile(response, fileName, observer);
        },
        (error) => {
          observer.error("download file error");
        }
      );
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(response);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }

  responseHandler_downloadFile(response: HttpResponse<any>, fileName: string, observer: any): void {
    saveAs(response, fileName);
    observer.next(response);
  }
}



