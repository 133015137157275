import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { routes } from './routes';
import { ProductService } from '../core/services/product.service';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes, { useHash: false }),
        PagesModule,
        AngularMultiSelectModule
    ],
    declarations: [],
    exports: [
        RouterModule
    ],
    providers: [ProductService]
})

export class RoutesModule {
    constructor(public menuService: MenuService, tr: TranslatorService) {
    }
}
