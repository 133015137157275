import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { APPCONFIG } from '../../app.config';
import { MenuService } from '../../core/menu/menu.service';
import { MENU } from '../../routes/menu';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public userInfo = null;
  public lng = new BehaviorSubject<string>('');
  dialogObservable = this.lng.asObservable();

  mediaLoaded = new Subject<any>();

  constructor(public translationService: TranslateService,public menuService:MenuService) {
    this.setLoggedUserInfo();
  }
  
  changeDialogState(value: string): void {
    this.lng.next(value);
  }

  setLoggedUserInfo() {
    let userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo);
      APPCONFIG.userRole = this.userInfo.role;
      this.setUserMenu(this.userInfo.role);
    } else {
      this.userInfo = null;
    }
  }

  getLoggedUserInfo() {
    return this.userInfo;
  }

  setUserMenu(role){
    this.menuService.addMenu(MENU[role]);
  }

  brkRef(obj){
    return JSON.parse(JSON.stringify(obj));
  }

}
