import { Injectable } from "@angular/core";
import { HttpResponse } from "@angular/common/http";
import { LoggingService } from "./log.service";
import { Observable } from "rxjs";
import { SettingsService } from "../settings/settings.service";
import { RestfulService } from "./restful.service";
import { getEndPointWithParams } from "../../../assets/js/util.js";
import { Multiselect } from "../interfaces/admin.interface";
import * as ENDPOINT from "../../shared/constant/endPoint.js";

@Injectable()
export class PortalService {
  constructor(private logger: LoggingService, private settings: SettingsService, private restful: RestfulService) {}

  getMultiselectItems(multiselectType: Multiselect): Observable<any> {
    let endPoint;
    switch (multiselectType) {
      case Multiselect.COMPANY:
        endPoint = ENDPOINT.multiselect_companyList;
        break;
      case Multiselect.PRODUCTS:
        endPoint = getEndPointWithParams(ENDPOINT.multiselect_product);
        break;
      case Multiselect.PRODUCT_FAMILY:
        endPoint = ENDPOINT.multiselect_familyList;
        break;
      case Multiselect.PRODUCT_TYPE:
        endPoint = ENDPOINT.multiselect_productType;
        break;
      case Multiselect.PRODUCT_CODE:
        endPoint = ENDPOINT.multiselect_code;
        break;
      case Multiselect.DELIVERY_AREA:
        endPoint = ENDPOINT.multiselect_delivery_area;
        break;
      case Multiselect.AVAILABILITY_PERIOD:
        endPoint = ENDPOINT.multiselect_availability_period;
        break;
      case Multiselect.UDM:
        // endPoint = ENDPOINT.product_code;
        break;
      case Multiselect.STATE:
        // endPoint = ENDPOINT.product_code;
        break;
      case Multiselect.RESELLER:
        endPoint = ENDPOINT.multiselect_reseller;
        break;
      case Multiselect.RESELLER_OWN:
        endPoint = ENDPOINT.multiselect_reseller_own;
        break;
      case Multiselect.USER:
        endPoint = ENDPOINT.multiselect_userList;
        break;
      case Multiselect.EMAIL:
        endPoint = ENDPOINT.multiselect_email;
        break;
      case Multiselect.ROLE:
        endPoint = ENDPOINT.multiselect_roles;
        break;
      case Multiselect.ROLE_LIST:
        endPoint = ENDPOINT.multiselect_rolesForList;
        break;
      case Multiselect.RESELLER_PRICE_LIST:
        endPoint = ENDPOINT.multiselect_resellerPriceList;
        break;
      case Multiselect.CURRENCY:
        endPoint = ENDPOINT.multiselect_currency;
        break;
      case Multiselect.PRICE:
        endPoint = ENDPOINT.multiselect_priceList;
        break;
      case Multiselect.ORDER_STATE:
        endPoint = ENDPOINT.multiselect_orderState;
        break;
      case Multiselect.PAYMENT_STATE:
        endPoint = ENDPOINT.multiselect_paymentState;
        break;
      case Multiselect.VAT:
        endPoint = ENDPOINT.multiselect_vat;
        break;
    }
    return this.get(endPoint);
  }

  getBackendVersion() {
    return this.get(ENDPOINT.backend_version);
  }

  getMultiselectItemsWithParam(multiselectType: Multiselect, param: any): Observable<any> {
    let endPoint;
    switch (multiselectType) {
      case Multiselect.RESELLER_PRICE_LIST:
        endPoint = getEndPointWithParams(ENDPOINT.multiselect_resellerPriceList_param, param);
        break;
    }
    return this.get(endPoint);
  }

  post(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  put(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response["outcome"];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(response);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }
}
