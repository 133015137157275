export const backend_version =          "/version";

export const priceList_reseller =       "/list/price/reseller/:idReseller/:companyCode";
export const order_productFamily =      '/product/product_family/:idReseller/:companyCode';
export const product_productListGroup = "/product/product_list_group/:idReseller/:companyCode/:currency/:languageCode";

export const company_all =     "/company/all";
export const company_info =    "/company/info/:idCompany";
export const company_create =  "/company/create";
export const company_update =  "/company/update";

export const reseller_landingInfo =       "/reseller/landing/info/:idReseller";
export const reseller_info =              "/reseller/info/:idReseller";
export const reseller_all =               "/reseller/all";
export const reseller_deleteAssociation = "/reseller/delete_association/:idReseller/with/:idCompany";
export const reseller_createReseller =    "/reseller/create";
export const reseller_update =            "/reseller/update";
export const associeted_companies =       "/reseller/info/company/:idReseller"

//export const order_cloudProducts =       "/order/cloud_products";  NON UTILIZZATO
export const order_orderSummary =                   "/order/summary/:language"; 
export const order_customerOrder =                  "/order/create";
export const order_orderService =                   "/order/order_service/:language"; //credo non venga utilizzato
export const order_attemptedPayment =               "/order/action/attempted_payment"
export const order_paymentSuccess =                 "/order/payment-success"; //NON CREDO VENGA UTILIZZATA
export const order_purchaseSummary =                "/order/purchase_summary/:language";  //NON CREDO VENGA UTILIZZATA
export const order_cart_view =                      "/order/cart/view/:languageCode";
export const order_cart_updateProductQuantity =     "/order/cart/update_product_quantity/:languageCode";
export const order_cart_remove_idPricelistProduct = "/order/cart/remove/:idPricelistProduct";
export const order_cart_portalCheckout =            "/order/cart/portal_checkout";
export const order_cart_empty =                     "/order/cart/empty";

export const license_all =                      "/license/all";
export const license_history =                  "/license/history/:idLicense";
export const license_licenseCode =              "/license/:licenseCode";
export const license_paymentState_change =      "/license/payment_state/change/:idLicense";
export const license_state_activate =           "/license/state/activate/:idLicense";
export const license_state_disable =            "/license/state/disable/:idLicense";
export const license_autorenew_toggle =         "/license/change_autorenew/:idLicense";

export const admin_user =             "/admin/user";
export const admin_userId =           "/admin/user/:idUser";
export const admin_userInvite =       "/admin/user/invite/:idUser";
export const admin_userList =         "/admin/user/list/";
export const admin_userCompany =      "/admin/user/company";
export const admin_userBusinessName = "/admin/user/business_name";

export const listOrder_all         = "/list/order/all";
export const listOrder_action      = "/list/order/action";
export const listOrder_orderDetail = "/list/order/order_detail/:languageCode/:idOrder";
export const listCustomer_all      = "/list/customer/all";
export const listOrder_allCustomer = "/list/order/customer/:idCustomer";
export const listOrder_allExport   = "/list/order/all/export";

export const product_listView =                "/product/all";
export const product_idProduct =               "/product/:idProduct";
export const product_createProduct =           "/product/create_product"
export const product_updateRegistry =          "/product/update_product"
export const product_createProductThresholds = "/product/create_product_thresholds"
export const product_updateThreshold =         "/product/update_product_thresholds";
export const product_idProductFamily =         "/product/productFamily/:idProductFamily";
export const product_productFamily =           "/product/productFamily/all";
export const product_createProductFamily =     "/product/create_product_family";
export const product_updateProductFamily =     "/product/update_product_family";
export const product_deleteProductFamily =     "/product/delete_product_family/:idProductFamily";
export const product_priceList =               "/product/price_list/:idPriceList";
export const product_productsInfo =            "/product/products/info";
export const product_productListByPricelist =  "/product/product_list_by_pricelist/:idPricelist/:currency/:languageCode";
export const product_termsAndCondition =       "/file/product/terms_and_conditions/:idProduct"
export const productFamily_termsAndCondition = "/file/product_family/terms_and_conditions/:idProductFamily"

export const listPrice_all =                        "/list/price/all";
export const listPrice_infoIdPriceList =            "/list/price/info/:idPriceList";
export const listPrice_create =                     "/list/price/create";
export const associated_reseller =                  "/list/price/:idPriceList/reseller";

export const multiselect_email =                    "/multiselect/email";
export const multiselect_rolesForList =             "/multiselect/roles_for_list";
export const multiselect_roles =                    "/multiselect/roles";
export const multiselect_companyList =              "/multiselect/company_list";
export const multiselect_reseller =                 "/multiselect/reseller";
export const multiselect_reseller_own =             "/multiselect/reseller/own";
export const multiselect_userList =                 "/multiselect/user_list";
export const multiselect_familyList_idCompany =     "/multiselect/family_list/:idCompany";
export const multiselect_familyList =               "/multiselect/family_list";
export const multiselect_product =                  "/multiselect/product_dropdown";
export const multiselect_productType =              "/multiselect/product_type_dropdown";
export const multiselect_code =                     "/multiselect/code_dropdown";
export const multiselect_resellerPriceList =        "/multiselect/reseller_priceList";
export const multiselect_resellerPriceList_param =  "/multiselect/reseller_priceList/:idPriceList";
export const multiselect_currency =                 "/multiselect/currencies";
export const multiselect_delivery_area =            "/multiselect/delivery_area";
export const multiselect_delivery_area_param =      "/multiselect/delivery_area/:idReseller";
export const multiselect_availability_period =      "/multiselect/availability_period";
export const multiselect_availability_period_param ="/multiselect/availability_period/:idDeliveryArea";
export const multiselect_priceList =                "/multiselect/price_list";
export const multiselect_orderState =               "/multiselect/order_state";
export const multiselect_paymentState =             "/multiselect/payment_state";
export const multiselect_vat =                      "/multiselect/vat"

