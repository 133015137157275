import { TransactionOKComponent } from './pages/landing-page/landing-page/pages/transaction-ok/transaction-ok.component';
import { UsersGuard } from './pages/portal-sections/users/users.guard';
import { LayoutComponent } from '../layout/portal-layout/layout.component';
import { LayoutBlankComponent } from '../layout/blank-layout/layout_blank.component';
export const routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'admin', pathMatch: 'full' },
            { path: 'admin', loadChildren: './pages/portal-sections/portal-sections.module#PortalSectionsModule' },
        ]
    },

    {
        path: '',
        component: LayoutBlankComponent,
        children: [
            { path: 'reseller/:idReseller/landing/:companyCode', loadChildren: './pages/landing-page/landing-page.module#LandingPageModule' },
            { path: 'purchase', loadChildren: './pages/landing-page/landing-page.module#LandingPageModule' },
        ]
    },

    {
        path: '',
        component: LayoutBlankComponent,
        children: [
            { path: 'login', loadChildren: './pages/authentication-sections/login/login.module#LoginModule' },
            { path: 'login/:id', loadChildren: './pages/authentication-sections/login/login.module#LoginModule' },
            { path: 'register', loadChildren: './pages/authentication-sections/register/register.module#RegisterModule' },
            { path: 'recover', loadChildren: './pages/authentication-sections/recover/recover.module#RecoverModule' },
            { path: 'activate/:id', loadChildren: './pages/authentication-sections/activate/activate.module#ActivateModule' },
            { path: 'lock', loadChildren: './pages/authentication-sections/lock/lock.module#LockModule' },
            { path: '404', loadChildren: './pages/error/error404/error404.module#Error404Module' },
            { path: '500', loadChildren: './pages/error/error500/error500.module#Error500Module' },
        ]
    },

    // Not lazy-loaded routes
    /*{ path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },*/

    // Not found
    { path: '**', redirectTo: '404' }

];
