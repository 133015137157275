import { NgModule } from '@angular/core';

import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavsearchComponent } from '../portal-layout/header/navsearch/navsearch.component';
import { UserblockComponent } from '../portal-layout/sidebar/userblock/userblock.component';
import { UserblockService } from '../portal-layout/sidebar/userblock/userblock.service';
import { FooterComponent } from '../portal-layout/footer/footer.component';

import { SharedModule } from '../../shared/shared.module';
import { HeaderComponent } from './header/header.component';

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        UserblockService
    ],
    declarations: [
        LayoutComponent,
        SidebarComponent,
        UserblockComponent,
        HeaderComponent,
        NavsearchComponent,
        FooterComponent
    ],
    exports: [
        LayoutComponent,
        SidebarComponent,
        UserblockComponent,
        HeaderComponent,
        NavsearchComponent,
        FooterComponent
    ]
})
export class LayoutModule { }
