import { Component, OnInit } from '@angular/core';

import { UserblockService } from './userblock.service';

@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
  user: any;
  businessName: string = "";

  constructor(public userblockService: UserblockService) {
    this.user = {
      name: localStorage.getItem("name"),
      picture: localStorage.getItem("picture") == null ? 'assets/img/logo-single.1.png' : localStorage.getItem("picture"),
      role: localStorage.getItem("role")
    };
  }

  ngOnInit() {
    if (this.user.role !== "SUPERADMIN") {
      this.userblockService.getBusinessName().subscribe((response) => {
        this.businessName = response.data.businessName;
        console.log(this.businessName);
      });
    }
  }

  userBlockIsVisible() {
    return this.userblockService.getVisibility();
  }

}
