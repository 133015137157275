import { NgModule } from '@angular/core';

import { LayoutBlankComponent } from './layout_blank.component';


import { SharedModule } from '../../shared/shared.module';

@NgModule({
    imports: [
      SharedModule
    ],
    providers: [
    ],
    declarations: [
      LayoutBlankComponent,
    ],
    exports: [
      LayoutBlankComponent
    ]
})
export class LayoutBlankModule { }
