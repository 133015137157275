import { OnInit, Directive, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../../../shared/services/common.service';

@Directive({
  selector: 'img[imgPreview]'
})
export class ImagePreview implements OnInit {

  element: any;

  @Input() set image(image: File) {
    this.setImageData(image)
  }

  constructor(public el: ElementRef, private commonService: CommonService) {
    this.element = this.el.nativeElement;
  }

  ngOnInit() { }

  setImageData(image: File) {
    if (image) {
      const reader = new FileReader();
      const mediaName = image.name
      const mediaType = image.type.startsWith('image/') ? 'image' : null
      const mediaDate = image.lastModified
      reader.onloadend = (e) => {
        this.element.src = reader.result
        const media = {
          name: mediaName,
          type: mediaType,
          fileData: reader.result,
          date: mediaDate
        }
        this.commonService.mediaLoaded.next(media);
      };
      reader.readAsDataURL(image);
    }
  }
}
