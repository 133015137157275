import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { ToasterModule } from 'angular2-toaster';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { AppService } from './core/services/base.service';
import { PortalService } from './core/services/portal.service';
import { LoggingService } from './core/services/log.service';
import { AuthService } from './core/services/auth.service';
import { RestfulService } from './core/services/restful.service';
import { CommonService } from './shared/services/common.service';
import { UserService } from '../app/core/services/user.service';
import { CustomerService } from '../app/core/services/customer.service';
import { PriceService } from '../app/core/services/price.service';
import { CompanyService } from './core/services/company.service';
import { LayoutModule } from './layout/portal-layout/layout.module';
import { LayoutBlankModule } from './layout/blank-layout/layout_blank.module';


// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        CoreModule,
        LayoutModule,
        LayoutBlankModule,
        SharedModule.forRoot(),
        RoutesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        ToasterModule.forRoot()
    ],
    providers: [CompanyService, PriceService, CustomerService, UserService, AppService, PortalService, LoggingService, AuthService,RestfulService,CommonService],
    bootstrap: [AppComponent]
})
export class AppModule { }
