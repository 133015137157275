const Home = {
    text: 'Home',
    translate: 'menu.home',
    link: '/admin/home',
    icon: 'icon-home'
};

const Users = {
    text: 'User',
    translate: 'menu.users',
    link: '/admin/users',
    icon: 'icon-user'
};

const Company = {
    text: 'Orders',
    translate: 'menu.companies',
    link: '/admin/company',
    icon: 'icon-grid'
};

const Resellers = {
    text: 'Resellers',
    translate: 'menu.resellers',
    link: '/admin/resellers',
    icon: 'icon-organization'
};

const Products = {
    text: 'Products',
    translate: 'menu.products',
    link: '/admin/products',
    icon: 'icon-tag',
    submenu: [
        {
            text: 'Registry',
            translate: 'menu.registry',
            icon: 'icon-note',
            link: '/admin/products/registry',
        },
        {
            text: 'Family',
            translate: 'menu.family',
            icon: 'icon-puzzle',
            link: '/admin/products/family'
        }
    ]
};

const ProductSales = {
    text: 'Product Sales',
    translate: 'menu.product_sales',
    link: '/admin/sale',
    icon: 'icon-basket'
};

const Orders = {
    text: 'Orders',
    translate: 'menu.orders',
    link: '/admin/order',
    icon: 'icon-bag'
};
const Price = {
    text: 'Pricing',
    translate: 'menu.pricing',
    link: '/admin/price',
    icon: 'icon-book-open'
};
const Customers = {
    text: 'Customers',
    translate: 'menu.customer',
    link: '/admin/customer',
    icon: 'fas fa-users'
};
const License = {
    text: 'License',
    translate: 'menu.license',
    link: '/admin/license',
    icon: 'icon-docs'
};


const Elements = {
    text: 'Elements',
    link: '/elements',
    icon: 'icon-chemistry',
    submenu: [
        {
            text: 'Buttons',
            link: '/elements/buttons'
        }
    ]
};

const headingMain = {
    text: 'Main Navigation',
    heading: true
};
// Price,
        // Orders
        // Products
export let MENU = {
    'SUPERADMIN': [
        Home,
        Users,
        Company,
        Resellers,
        Orders,
        Customers,
        Price,
        License,
        Products
    ],
    'HEADQUARTER': [],
    'SHOP': [],
    'COMPANY_ADMIN': [
        Home,
        Users,
        Resellers,
        Orders,
        Price,
        License,
        Products
    ],
    'RESELLER_ADMIN': [
        Home,
        Users,
        Resellers,
        Orders,
        Price,
        License,
        ProductSales
    ],
    'RESELLER_AGENT': [
        Home,
        Users,
        Orders,
        Price,
        License,
        Products
    ]
};

